import { Modal, ModalTitle, Text, LinkButton, IconButton } from '@carvertical/ui';
import { useTranslation } from 'next-i18next';
import { useModal } from '../../hooks';
import { useMarketSwitcher } from './hooks';

const LanguageSelectionModal = () => {
  const { t } = useTranslation();
  const { selectedMarket } = useMarketSwitcher();
  const { languageModalType } = useMarketSwitcher();
  const { open: openSuggestCheckoutModal } = useModal('suggestCheckout');
  const { close: closeLanguageSelectionModal, isModalOpen: modalOpen } =
    useModal('languageSelection');

  const backToMarketSelection = () => {
    closeLanguageSelectionModal();
    openSuggestCheckoutModal();
  };

  return (
    <Modal
      open={modalOpen}
      onClose={closeLanguageSelectionModal}
      size="m"
      overlayClassName="bg-black/15"
      contentClassName="text-center pt-0 pb-6"
      boxClassName="max-w-80 max-h-32 sm:max-h-40 rounded-l mx-3"
      title={t('marketSwitcher.languageTitle')}
    >
      <div className="mb-4 flex w-full items-center justify-between">
        {languageModalType === 'checkout' && (
          <IconButton
            variant="transparent"
            icon="arrow-left"
            label={t('general.backAction')}
            onClick={backToMarketSelection}
            className="absolute top-3"
          />
        )}

        <ModalTitle className="flex-grow text-center">
          {t('marketSwitcher.languageTitle')}
        </ModalTitle>
      </div>

      {selectedMarket ? (
        <ul className="m-0 flex list-none flex-col items-center gap-3 p-0">
          {selectedMarket.languages.map(({ label, slug }) => (
            <li key={slug}>
              <LinkButton
                title={label}
                href={slug}
                size="m"
                className="max-w-min transition hover:no-underline focus:outline-none focus-visible:ring focus-visible:ring-offset-2"
              >
                {label}
              </LinkButton>
            </li>
          ))}
        </ul>
      ) : (
        <Text>{t('general.noResultsLabel')}</Text>
      )}
    </Modal>
  );
};

export { LanguageSelectionModal };
