import { cn } from '@carvertical/utils/styling';
import { Modal, ModalTitle, Text } from '@carvertical/ui';
import { useTranslation } from 'next-i18next';
import Flag from 'components/common/Flag';
import { useModal } from '../../hooks';
import { useSuggestCorrectMarket, useSuggestedMarket } from './hooks';
import { MarketSelection } from './MarketSelection';

const SuggestCheckoutModal = () => {
  const { t } = useTranslation('geo');
  const { market } = useSuggestCorrectMarket();
  const { isModalOpen, close } = useModal('suggestCheckout');
  const suggestedMarket = useSuggestedMarket();

  if (!suggestedMarket) {
    return null;
  }

  return (
    <Modal
      open={isModalOpen}
      size="s"
      onClose={close}
      overlayClassName="bg-black/15"
      footer={<MarketSelection />}
    >
      <div className="flex flex-col items-center gap-3">
        <div className="flex justify-center">
          <Flag
            countryCode={market.countryCode}
            className={cn('mt-2.5 translate-x-[40%] shadow-[0_0_0_1px_theme(colors.white)]')}
            rounded
            as={undefined}
            size={undefined}
          />

          <Flag
            countryCode={suggestedMarket.countryCode}
            className={cn('mt-2.5 -translate-x-[10%] shadow-[0_0_0_1px_theme(colors.white)]')}
            rounded
            as={undefined}
            size={undefined}
          />
        </div>

        <ModalTitle align="center">{t('checkout.title')}</ModalTitle>

        <Text variant="m" align="center" textColor="darkSecondary">
          {t('checkout.text')}
        </Text>
      </div>
    </Modal>
  );
};

export { SuggestCheckoutModal };
