import { Button } from '@carvertical/ui';
import { useTranslation } from 'next-i18next';
import { useEffect, useState } from 'react';
import i18next from 'i18next';
import { getCountryName } from 'services/countries';
import { useModal, useMarket } from '../../hooks';
import { useMarketSwitcher } from '../MarketSwitcher/hooks';
import { useSuggestCorrectMarket, useCheckoutUrl, useSuggestedMarket, useType } from './hooks';

const MarketSelection = () => {
  const { t } = useTranslation(['geo', 'common']);
  const { getMarketLanguages } = useMarket();
  const { confirmMarketSelection, market } = useSuggestCorrectMarket();
  const { close: closeSuggestCheckoutModal } = useModal('suggestCheckout');
  const { open: openLanguageSelectionModal } = useModal('languageSelection');
  const { selectMarket, switcherMarkets, setLanguageModalType } = useMarketSwitcher();

  const type = useType();
  const checkoutUrl = useCheckoutUrl();
  const suggestedMarket = useSuggestedMarket();
  const switchToMarketLabel = useSuggestedCountryTranslatedLabel(suggestedMarket?.countryCode);

  const countryName = getCountryName(market.countryCode);
  const { slug: currentMarketSlug } = getMarketLanguages(market.countryCode, checkoutUrl)[0];

  const stayInCurrentMarket = () => confirmMarketSelection();

  const switchToMarket = () => {
    const selectedMarket =
      switcherMarkets.find(
        (switcherMarket) => switcherMarket.countryCode === suggestedMarket?.countryCode,
      ) || switcherMarkets[0];

    selectMarket(selectedMarket);

    if (type === 'checkout') {
      closeSuggestCheckoutModal();
    }

    setLanguageModalType(type === 'checkout' ? 'checkout' : 'suggestion');
    openLanguageSelectionModal();
  };

  if (!suggestedMarket) {
    return null;
  }

  return (
    <div className="flex flex-col items-stretch gap-2">
      <Button onClick={switchToMarket}>{switchToMarketLabel}</Button>

      <Button
        href={type === 'checkout' ? currentMarketSlug : undefined}
        variant="outlined"
        onClick={stayInCurrentMarket}
      >
        {t('stayInCountryAction', { country: countryName })}
      </Button>
    </div>
  );
};

function useSuggestedCountryTranslatedLabel(countryCode?: string) {
  const { t } = useTranslation('geo');
  const [translatedText, setTranslatedText] = useState('');
  const suggestedCountryName = getCountryName(countryCode, { inNativeLanguage: true });
  const fallbackLabel = t('switchToCountryAction', { countryCode: suggestedCountryName });

  useEffect(() => {
    if (!countryCode) {
      return;
    }

    const loadTranslation = async () => {
      // A separate i18next instance needed to avoid conflicts with the main app's translations
      // This allows us to load correct language
      // Other methods causes fallback to default EN translations
      const i18nInstance = i18next.createInstance();

      await i18nInstance.init({
        lng: countryCode,
        resources: {
          [countryCode]: {
            geo: (await import(`../../../../../public/locales/${countryCode}/geo.json`)).default,
          },
        },
      });

      const translation = i18nInstance.t('geo:switchToCountryAction', {
        country: suggestedCountryName,
      });
      setTranslatedText(translation);
    };

    loadTranslation();
  }, [countryCode, suggestedCountryName]);

  return translatedText || fallbackLabel;
}

export { MarketSelection };
