import { j as r } from "./chunks/jsx-runtime.82a9ddf6.js";
import "./anchorOrButton.mjs";
import "./externalLink.mjs";
import "./button.mjs";
import "./linkButton.mjs";
import { I as o } from "./chunks/IconButton.db48bd61.js";
import { h as i } from "./chunks/styles.b9d17517.js";
import "react";
import "./chunks/clsx.0d472180.js";
import "./skeletonLoader.mjs";
import "./chunks/useTranslatableUILabel.cc84b4da.js";
import "./chunks/TranslatableUIProvider.f5945ac7.js";
import "./skeletonWrapper.mjs";
import "./spinner.mjs";
import "./heading.mjs";
import "./chunks/cn.2ea4f521.js";
import "./chunks/TypographyElement.80f8cab9.js";
import "./text.mjs";
import "./icon.mjs";
import "./chunks/index.29668fd9.js";
import "./chunks/motion.24089995.js";
const t = ({ ...e }) => /* @__PURE__ */ r.jsx(o, { className: i.root, size: "xs", variant: "transparent", ...e });
try {
  t.displayName = "TextFieldIconButton", t.__docgenInfo = { description: "", displayName: "TextFieldIconButton", props: { label: { defaultValue: null, description: "", name: "label", required: !0, type: { name: "string" } }, as: { defaultValue: null, description: "", name: "as", required: !1, type: { name: "ElementType<any, keyof IntrinsicElements>" } }, iconClassName: { defaultValue: null, description: "", name: "iconClassName", required: !1, type: { name: "string" } }, unstyled: { defaultValue: null, description: "", name: "unstyled", required: !1, type: { name: "boolean" } }, icon: { defaultValue: null, description: "", name: "icon", required: !0, type: { name: "IconMapMember" } } } };
} catch {
}
export {
  t as TextFieldIconButton
};
