import { Button } from '@carvertical/ui';
import { cn } from '@carvertical/utils/styling';
import { useTranslation } from 'next-i18next';
import { useClickAway } from 'react-use';
import { useRef } from 'react';
import Flag from 'components/common/Flag';
import { useRouteData } from 'context/RouteDataProvider';
import { getCountryName } from 'services/countries';
import { getCountry } from 'utils/locale';
import { useModal } from '../../hooks';
import { MarketSwitcherModal } from './MarketSwitcherModal';

type MarketSwitcherProps = {
  buttonClassName?: string;
};

const BUTTON_DATA_ATTRIBUTE = 'data-point-button';

const MarketSwitcher = ({ buttonClassName }: MarketSwitcherProps) => {
  const { t } = useTranslation();
  const { locale, market } = useRouteData();
  const modalRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const { isModalOpen: hamburgerModalOpen } = useModal('hamburger');
  const { isModalOpen: languageSelectionModalOpen } = useModal('languageSelection');
  const { toggle: toggleMarketSelectionModal, close: closeMarketSelectionModal } =
    useModal('marketSelection');

  useClickAway<MouseEvent>(buttonRef, (event) => {
    const buttonClicked =
      event.target instanceof HTMLButtonElement &&
      event.target.attributes.getNamedItem(BUTTON_DATA_ATTRIBUTE);
    const insideModalClicked = modalRef.current?.contains(event.target as HTMLDivElement);
    const closeAllowed =
      !buttonClicked && !hamburgerModalOpen && !insideModalClicked && !languageSelectionModalOpen;

    if (closeAllowed) {
      closeMarketSelectionModal();
    }
  });

  return (
    <>
      <Button
        className={cn(
          'bg-white pl-1 pr-1.5 uppercase shadow-none transition-colors duration-100 ease-out enabled:hover:bg-transparent enabled:hover:text-blue enabled:hover:shadow-none',
          buttonClassName,
        )}
        size="m"
        ref={buttonRef}
        variant="outlined"
        onClick={toggleMarketSelectionModal}
        aria-label={`${t('marketSwitcher.changeLabel')} ${getCountryName(market.countryCode)}`}
        {...{ [BUTTON_DATA_ATTRIBUTE]: true }}
      >
        <span className="flex flex-row items-center gap-1">
          <Flag
            as={undefined}
            aria-hidden="true"
            countryCode={market.countryCode}
            size={24}
            rounded
            className="-my-1"
          />

          {locale.length > 2 ? locale : getCountry(locale)}
        </span>
      </Button>

      {!hamburgerModalOpen && <MarketSwitcherModal ref={modalRef} />}
    </>
  );
};

export { MarketSwitcher };
export type { MarketSwitcherProps };
